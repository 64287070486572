.categories .view-details-btn {
  margin-left: 5px;
}

.categories .input-select {
  padding-right: 0.2rem;
  margin-right: 0.5rem;
}

.categories .bread-crumb {
  padding-bottom: 0.5rem;
}

.highlight {
  border: 2px #168c87 solid !important;
}

.highlight-disclaimer {
  margin-left: 1rem;
}

.right-justified {
  margin-left: auto;
}

#grid_segment_assort {
  height: calc(100% - 2.25rem);
  position: static;
}

#grid_segment {
  height: calc(100% - 0.5rem);
  position: static;
}

.listing-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

#clear_quantities {
  cursor: pointer;
  color: #4183c4;
  text-decoration: underline;
}

.product-grid #grid_segment {
  margin: 0;
}

.comparison-field-mobile {
  background-color: #f2f2f2;
  padding: 0;
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
}

.mobilepacks {
  margin: 0.5rem !important;
  padding: 0 !important;
}

.grid-mobilecmp {
  padding-top: 0.2rem !important;
  margin: 0px !important
}

.remove-all-span.mob {
  padding-left: 0rem !important;
  margin-bottom: 1rem;
}

.product-field {
  font-weight: bold;
}

.ui.grid>[class*="seven wide"].column.mobilepacks {
  width: 48% !important;
  padding: 0 0px !important;
  margin: 0 2px !important;
}

.mobilepacks:nth-child(2) .comparison-field-mobile span {
  visibility: hidden;
}

.mobindicator {
  height: 6rem;
  max-height: 6rem;
}

.mobcompprice {
  height: 2rem;
  max-height: 2rem;
  margin: 0.4rem 0;
}

.cmpre-green {
  color: #388e3c;
}


.add-to-catalog-button {
  margin-bottom: 0.1rem !important;
  font-weight: bolder;
}

.disabledMessage {
  color: #c8102e;
  font-size: 0.8rem;
  padding: 0.25rem 0;
  text-align: center;
  max-width: 8.3rem;
}

.fluid.button+.disabledMessage {
  max-width: inherit;
}

.place-order-btn+.disabledMessage {
  max-width: inherit;
  padding-right: 0.45rem;
  text-align: right;
}

.product-grid .disabledMessage {
  text-align: center;
}
.compare-container .product-field.long-desc {
  overflow: hidden;
}
.directShipHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.dropShipBtnWrapper {
  min-width: 280px;
}
.dropShipBtnWrapper span {
  color: #c8102e;
  display: inline-block;
  margin-left: 1rem;
  text-align: center;
}
.directShipQtyMsg {
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
}
.directShipTitle {
  font-size: 1rem;
}
.directShipQtyMsg strong {
  color: #c8102e;
}
.directShipQtyMsg p {
  font-family: HelveticaNeueBold;
  font-size: 1.1rem;
  margin-bottom: 0;
}
.directShipQtyMsg > span {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: #c8102e;
  line-height: 1;
}
.vendorBreadcrumb {
  cursor: pointer;
  font-family: HelveticaNeue;
  text-decoration: underline;
  padding-top: 0;
  padding-bottom: 0;
  color: #4183c4;
  font-size: .7rem;
}
.dsNonStockedItem span {
  font-size: 0.7rem;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .directShipQtyMsg {
    flex-wrap: wrap;
  }
  .row1 {
    height: 18rem;
  }

  .vendor-span {
    font-size: 0.8rem;
  }

  .compareProductName {
    font-size: 1rem;
    font-weight: bold;
  }

  .categories .input-select {
    border: 0.1rem;
    border-radius: 0rem;
    background-color: #ffffff;
  }

  #list_icon {
    padding: 0 1.25rem 0 0.5rem;
  }

  .iconPopUp {
    background-color: red;
  }

  .products_padding>select {
    margin-left: 0.5rem;
  }

  .sortByCategories {
    margin-top: 1rem;
  }

  .product-field {
    font-weight: normal;
  }

  .listing-header-left {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .row1 {
    height: 18rem;
  }

  .vendor-span {
    font-size: 0.8rem;
  }

  .compareProductName {
    font-size: 1rem;
    font-weight: bold;
  }

  .categories .input-select {
    border: 0.1rem;
    border-radius: 0rem;
    background-color: #ffffff;
  }

  .product-field {
    font-weight: normal;
  }

  .listing-header {
    flex: 1 1 auto;
  }
}

@media print {
  html {
    font-size: 16px;
  }

  .listingDescription {
    font-size: 1.2rem;
  }
}

@media (max-width:768px) {
  .directShipQtyMsg p {
    font-size: 1.3rem;
    flex-basis: 100%;
    text-align: center;
  }
  .directShipQtyMsg strong {
    display: block;
  }
  .dropShipBtnWrapper {
    margin-bottom: 0.5rem;
  }
}
@media (min-width:1241px) {
  .directShipQtyMsg {
    position: sticky;
    top: 7rem;
    z-index: 1;
    background: #f2f2f2;
    align-items: baseline;
    margin-bottom: 0;
  }
  #ds_pagination {
    position: sticky;
    top: 8.5rem;
    z-index: 10;
    background: #f2f2f2;
    margin: 0;
  }
  #classViewTable[data-isDirectShip="true"] thead td {
    position: sticky;
    top: 13.5rem;
  }
  #ds_classicAddBtn.classicAddBtn {
    top: 10.7rem;
  }
}
@media (min-width:1024px) {
.HeaderFixedPLP{
  position: fixed;
  width:98%;
  background-color: #f2f2f2;
  z-index: 10;
  padding: 23px 10px 0;
}
.DOMHeader{
  background-color: #f2f2f2;
  z-index: 10;
  width: 98%;
  top:7rem;
  position: fixed;
  padding: 10px 10px 0;
}
#assortment_info_dom {
  margin-top:12rem !important;
  border:none;
}
#assortment_info_dom .HeaderFixedPLP{ 
  top:15.5rem;
  padding-top:0;
}
.plpPageFilter{
  top:10rem;
  margin-bottom:13rem;
}
#assortment_info_dom .plpPageFilter{ 
  top:0rem;
}
.none.plpPageFilter{
  margin-bottom: 0;
}
.plpPageListing{
  top:10rem;
  margin-bottom: 12rem;
}
#assortment_info_dom .plpPageListing{ 
  top:0rem;
}
.plpPadding{
  padding:0 10px !important;
}
#assortment_info_dom .plpPadding{
  background: #f2f2f2;
}
.plpPageFixed{
  position: fixed;
    top: 14.5rem;
    width: 80%;
    z-index: 10;
    background: #f2f2f2;
    padding: 0 10px 5px;
    margin: 0;
}
#assortment_info_dom .plpPageFixed{ 
  top:18rem;
}
.sixteen .plpPageFixed {
  width:100%;
}
}
@media (min-width:1248px) {
  .plpPageFixed{
    top:13rem;
  }
}
.paginationSearch{
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.paginationSearch label{
  margin-right: 10px;
}
#search_PLPPagination{
  width: 4rem;
  height: 1.7rem; 
  line-height: 0.5rem;
  border-radius: 0rem;
  padding-left: 0.625rem;
  border-style: none;  
}
.plp_search_icon_address{
    width: 1.3rem;
    height: 1.3rem;
    position: relative;
    right: 1.8rem;
    top: 0.7rem;
    bottom: 0.01rem;
    z-index: 1;
    margin-bottom: 0.3rem;
    cursor: pointer;
}
.paginationButton{
  width:50px;
  padding:5px 0 !important;
}
.inlineGrouptext{
  display: flex;
  align-items: center;
  border: solid 1px rgb(147, 146, 146) !important;
  margin-right:5px;
}
.rightLabel{
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-left:solid 1px  rgb(147, 146, 146) !important;
}
@media (min-width:767px) {
.headerPagination{
  display: flex;
  align-items: baseline;
}
}