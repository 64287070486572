.main-container {
  padding: 0.5rem 1em;
}

.vendorNum {
  border: none;
  color: #4183c4;
  cursor: pointer;
  background-color: transparent;
}

.far.fa-address-card {
  font-size: 1.4rem;
  margin-right: 10px;
  color: #000000;
}
.vendor-Headuline{
font-weight: bold ;
text-decoration: underline;
}
.vendor-Head{
  font-weight: bold;
}
#vendor-header-desc {
  padding-right: 0;
  width: 7.5% !important;
}

#vendor-header-desc > h5 {
  margin-top: 0.3em;
}

#vendor-search {
  padding-left: 6px;
}

#vendor-search > .search > .input > input {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
.columns{
  padding-bottom:1rem;

}
.alphabet-accordion {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  width: 98%;
  overflow: hidden;
  min-height: 2rem;
}

.ui.styled.accordion {
  width: 100%;
}

.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
  background: #d6d6d6;
  color: #000000;
  text-align: left;
  padding: 0;
}

.ui.styled.accordion .active.title {
  background: #d6d6d6;
  color: #000000;
  text-align: left;
}

.accordion-content {
  width: 100%;
  text-align: left;
}

.ui.accordion .accordion .active.content,
.ui.accordion .active.content {
  display: block;
  color: #2b77f2;
  text-align: left;
}

.showAtoZ {
  color: #000000;
  font-size: 16px;
  border: 0.1rem solid;
  cursor: pointer;
  position: relative;
  right: 47rem;
}
.AtoZButton {
  position: absolute;
  width: 10rem;
  overflow-y: auto;
  height: 10rem;
  padding: inherit;
  z-index: 1;
}
.AtoZButton:hover {
  display: block;
}
.AtoZButton div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #ffffff;
  -webkit-appearance: unset;
}

.alphabet-filter-wrapper:hover .AtoZButton {
  display: block;
}

.anchor-blue {
  color: #2b77f2;
}

.alphabet-filter-wrapper > button:focus {
  outline: none;
}

.vendor-segment {
  width: 100%;
  margin-bottom: 20px;
}

.vender-search-header {
  height: 1.85rem;
  width: auto;
  color: #181818;
  font-family: HelveticaNeue;
  line-height: 1.85rem;
  font-weight: bold;

}

.plus-minus-icon {
  position: absolute;
  right: 3rem;
}

.alphabets {
  margin-left: 22% !important;
}

#search_div_address .search-box_address {
  width: 9.5rem;
  border-radius: 0rem;
  padding-left: 0.625rem;
  font-size: 1rem;
  float: left;
  border-color: rgb(169, 169, 169) !important;
  margin-left: 0.5rem;
  height: 1.5rem;
}

.alphabets.ui.list > .item {
  padding: 0 10px;
  border-right: 1px solid #4183c4;
  margin-left: 0;
}
.alphabets.ui.list > .item:last-child {
  border-right: 0;
}

.alphabets-device {
  display: none;
}

.vendor-loader > .ui.loader{
  position: fixed;
}
.wordBreak {
  margin-top: 2rem;
}
.top-container{
  margin-top: 1rem;
}

.wordBreak .grid p {
  word-wrap: break-word;
  margin:0px;
}
.mobileLinkText {
  display: none;
  font-family: HelveticaNeue;
  text-decoration: underline;
  font-size: 0.7rem;
  text-transform: uppercase;
  padding-bottom: 0.3rem;
}
.vendorDetailInfo{
  max-height:500px;
  overflow: hidden;
}
.vendorDetailInfo {
  overflow-y: auto;
}
.vendorSearchWrapper {
  display: flex;
}
.vendorTypeDiv input {
  width: 1rem;
  height: 1rem;
  margin: 0 0.25rem 0 0.5rem;
  vertical-align: middle;
}
.alphabeticalDiv {
  display: flex;
  flex-wrap: wrap;
}
.alphabeticalDiv input {
  position: absolute;
  pointer-events: none;
  visibility: hidden;
}
.alphabeticalDiv > div {
  margin: 0 0.25rem;
}
.alphabeticalDiv label {
  cursor: pointer;
}
.alphabeticalDiv input:checked + label {
 text-decoration: underline;
 color: #1e70bf;
}
.letterHeading {
  border-bottom: 1px solid #7e7979;
  font-weight: bold;
}
.alphabet-filter-wrapper {
  min-width: 80%;
}
.vendorSearchWrapper .filterorderstatus {
  margin-top: 0;
}
.alphabet-filter-wrapper .alphabet-accordion {
  margin-top: 0;
}
.linkStyle {
  color: #1e70bf;
  display: inline-block;
    vertical-align: top;
}
.vendorTypeDiv label {
  cursor: pointer;
}
/* .vendorAddressInfo {
  max-height: 4.5rem;
  overflow: hidden;
}
.vendorAddressInfo {
  overflow-y: auto;
} */
@media only screen and (max-width: 767px) {
  .showAtoZ {
    background-color: #ffffff;
    right: 0;
    margin-left: 0.5rem;
  }
  #filter_width_ipad {
    margin-bottom: 1rem;
  }
  .AtoZButton div {
    text-align: center;
  }
  .mobileDiv .fa-address-card {
    display: none;
  }
  .mobileLinkText {
    display: block;
  }
  .ui.button.vendorViewBtn {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .showAtoZ {
    right: 0;
    background-color: #ffffff;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .showAtoZ {
    right: 32rem;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .ui.horizontal.list.alphabets {
    display: none;
  }
  .alphabets-device {
    display: block;
    position: relative;
  }
  .AtoZButton {
    height: 9rem;
    top: 26px;
    left: 0;
  }
}
a.accordion-title {
  color: #181818;
  display: inline-block;
  padding: 0.75em 1em;
  width: 100%;
}