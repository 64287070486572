.filter-disclaimer{
  font-family: HelveticaNeue;
  font-weight: bold;
  font-size:0.7rem;
  color: #0c0c0c;
}
.expand-arrow{
  font-size:1.5rem;
  font-weight:400;
  cursor:pointer;
}
.filter-hide{
  float: right;
  padding-right: 0.5rem;
  position: absolute;
  top: 0;
  right: 0.1rem;
}
.shrink-arrow{
  font-size:0.78rem;
  line-height: 1.57rem;
  font-weight:400;
  cursor: pointer;
}

.filter-section{
  padding-bottom: 0rem !important;
  margin-top: 0.2rem !important;
}
.filter-section{
  background-color: #fff;
  margin-bottom:0.35rem;
  padding-bottom: 0rem !important;
  margin-top: 0.2rem !important;
}
.filter-head, .refine-filter-head{
  font-family: HelveticaNeue;
  padding-bottom: 0.3rem ;
  padding-top: 0.3rem;
  padding-left: 0.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
  text-align :left;
  color: #fff;
  margin-bottom:0.35rem;
}
.filter-head i, .refine-filter-head i{
float: right;
}
.applied.filter-head,.applied.refine-filter-head{
background-color: #168c87;
}
.filter-label{
  font-family: HelveticaNeue;
}

.noproducts-suggestion {
  display: flex;
  justify-content: center;
  align-items: center;
  color:'#CC0000';
}
.filter-head i.icon,.refine-filter-head i.icon{
  margin-right: 0 !important;
  }
.filter-section i.icon{
  margin-right: 0!important;
}
.filter-head i, .refine-filter-head i{
  float:right;
}
.filter-header{
  position: relative;
}
.filter-header i{
  float: none;
  margin-left: 0.72rem;
}
.assortmentFilters{
  min-width: 12rem;
} 
.FilterMonthly{
  width: 18rem;
  margin-left: -1rem;
}
#filter_symbol{
color:#0c0c0c;
}
#filter_symbol.applied-filter{
color:#168c87;
}
.applied-filter{
color:#168c87;
}
.applied-filter-head{
background-color:#168c87;
}
#filter_icon{
font-size: 1rem;
float: right;
padding-right: 0.5rem;
}
.filter-cb-wrap{
display: block !important;
padding: 0.255rem 0.25rem;
}
.filter-checkbox{
padding: 0.3rem 0.5em;
font-size: 1rem;
}
@media only screen and (min-width: 320px) and (max-width: 667px) {

  .filters{
    width: 100%;
  }
}
#fs-Category_CTB #contents-1{
    max-height: 350px;
    overflow: scroll;
}