#classViewTable {
  margin-top: 0;
}

#classViewTable thead td {
  background-color: rgb(49 49 49);
  padding: 0.5rem 0.25rem;
  font-size: 0.65rem;
  text-align: center;
  font-family: 'HELVETICANEUEBOLD';
  text-transform: uppercase;
  text-align: left;
  color: #fff;
  padding: 0.5rem 0.1rem !important;
  line-height: 1.1;
  z-index: 1;
}

#classViewTable .qty-box {
  min-height: 1.5rem;
}

#classViewTable tbody td {
  vertical-align: middle;
  line-height: 1.1;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: #fff;
  border-top: 1px solid #dedede !important;
  font-size: 0.7rem;
  padding: 0 0.25rem !important;
}

#classViewTable.retailTable thead td {
  top: 6.7rem;
}

#classViewTable.impulseTable thead td {
  top: 6.7rem
}

#classViewTable .card_list_wrap {
  width: 2.75rem;
}

#classViewTable .card_list_wrap img {
  max-width: 100%;
}

#classViewTable .card_list_wrap>a {
  height: 2.75rem;
}

#classViewTable .buttonVisible {
  font-size: 0.45rem;
  height: auto;
  width: 2.75rem;
  text-align: center;
  padding: 0;
}

.classicDesc {
  font-size: 0.7rem;
}

#classViewTable .qucikViewDiv {
  height: 1rem;
}

#classViewTable thead>td:last-child {
  /*text-align: right;
      padding-right: 0.5rem !important; */
}

#classViewTable tbody>tr td:last-child {
  /*text-align: right;
      padding-right: 0.5rem !important; */
}

.resProductDesc {
  position: relative;
}

#classViewTable .favCompWrap {
  position: absolute;
  right: 0.5rem;
  transform: translateY(-0.25rem);

}

#classViewTable .resProductDesc td {
  border: 0 !important;
}

#classic_icon {
  padding-right: 0.25rem;
}

#list_icon {
  padding-left: 0.25rem;
}

#classViewTable .listingDescription {
  font-size: 0.75rem !important;
  line-height: 1.1;
  padding-right: 5px;
}

#classViewTable .vendor-span {
  padding-left: 0;
  text-decoration: none;
  font-size: 0.7rem;
}

#classViewTable .vendor-span a {
  word-break: break-word;
}

#classViewTable .compare-n-fav {
  display: flex;
  margin-bottom: 0;
  margin-right: 0.25rem;
}

.favRemoveWrap {
  position: absolute;
  right: 1.5rem;
  transform: translateY(-0.5rem);
  min-height: auto !important;
  background: transparent !important;
}

.favRemoveWrap .content_checkbox_remove {
  font-size: 0.7rem;
  font-weight: bold;
}

.favRemoveWrap .checkbox {
  transform: scale(0.85);
}

#classViewTable .compare-n-fav .heart {
  margin: 0;
}

#classViewTable .ui.checkbox label:before {
  border: 1px solid #E5E5E5;
}

#classViewTable .fav_icon_hover {
  top: 0rem;
  right: 1.5rem;
  background-color: #fff;
}

.header-top.ui {
  z-index: 100;
}

.classicFavAddBtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

#classViewTable.favClassicTable {
  margin-bottom: 0;
}
#classViewTable.favClassicTable tbody td {
  padding: 0 !important;
}
.favCheckBox {
  transform: scale(0.8);
  transform-origin: bottom;
}

.favorite-hidepage {
  align-items: center;
}

.classicFavAddBtn button {
  max-width: 7rem;
  font-size: 0.8rem !important;
}

.classicAddBtn {
  margin: 0;
  text-align: right;
  padding: 0.5rem 0;
  background: #f2f2f2;
  z-index: 2;
}

.classicAddBtn #plp_add_to_cart {
  width: auto;
  display: inline-block;
  float: inherit;
}

#classViewTable .image-icon-display {
  align-items: center;
}

#classViewTable .image-icon-display>span:last-child {
  margin-right: 0.5rem;
}

.classicAddBtn .ui.red.button {
  font-size: 0.9rem;
  padding: 0.5rem 0.7rem;
}

.favDivWrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
}

.favDivWrap .compare_checkbox {
  transform: scale(0.8);
  transform-origin: top;
}

.removeEvent.titleCell {
  pointer-events: none;
}

.removeEvent.titleCell:after {
  display: none;
}

.listing-header {
  margin-bottom: 0 !important;

}

.listing-header .ui.pagination.menu {
  min-height: 2rem;
}

#classViewTable .warning {
  font-size: 0.725rem;
}

#classViewTable .miu-wrap {
  display: inline-flex;

}

#classViewTable .madeinusa_indicator {
  height: 1.35rem;
}

#classViewTable .indicators-div {
  display: flex;
  align-items: center;
}

#classViewTable .icon-display {
  font-size: 0.7rem;
}

.alternateLabel {
  min-width: 4rem;
  width: 7rem;
}

.boldText {
  font-family: HelveticaNeueBold;
}

#classViewTable .restricted-product {
  font-size: 0.7rem;
}

#classViewTable .DescriptionDialog {
  font-size: 0.9rem;
}

.invalidBox {
  border: 2px solid red;
  color: red;
}

.ui-dialog-content {
  padding: 0.5rem;
}

.titleCell {
  cursor: pointer;
  position: relative;
  background: none;
  border: 0;
  color: #fff;
  font-family: 'HELVETICANEUEBOLD';
  text-transform: uppercase;
  outline: none !important;
  padding-left: 0;
  padding: 0;
  text-align: left;
  transition: 0.2s;
}

button.titleCell:after {
  position: absolute;
  content: '';
  width: 100%;
  background: #f2ebeb;
  bottom: -2px;
  height: 1.5px;
  left: 0;
  transition: 0.2s;
}

button.titleCell:hover {
  color: #4183c4;
}

button.titleCell:hover::after {
  background: #4183c4;
  transform: scale(0.9);
}

.compareWrap {
  position: relative;
}

.comp_icon_hover {
  position: absolute;
  top: 0rem;
  right: 3rem;
  font-size: 0.85rem;
  font-weight: 700;
}

.ui.top.right.pointing.dropdown>.menu {
  z-index: 100;
}

@media(min-width:1025px) {
  .rc-menu-submenu.rc-menu-submenu-popup {
    z-index: 999;
    border: 1px solid #c8102e;
  }
}
@media(min-width:1241px) {
  .staticFilter>div {
    position: sticky;
    top: 4.5rem
  }

  .plpPageFixed + .classicAddBtn {
    position: sticky;
    top: 15.4rem;
  }
  .plpPageFixed + .classicAddBtn + #classViewTable thead td {
    position: sticky;
    top: 18.3rem;
   }
   #assortment_info_dom .classicAddBtn {
      top: 20.3rem;
   }
   #assortment_info_dom #classViewTable thead td {
      top: 23.1rem;
   }
}