.allOrdersTable {
  margin-top: 1rem;
}

#allOrdersTable {
  margin-left: 1rem;
  font-family: HelveticaNeueBold;
}

#font-size-orders {
  font-size: 0.9rem !important;
  font-family: HelveticaNeueBold;
}

.allOrderFilters {
  width: max-content;
}
.orderstatusMargin {
  margin-top: 2rem !important;
}

.allOdrHeader {
  background-color: rgb(242, 242, 242) !important;
  color: #000000 !important;
  text-align: center !important;
  text-decoration-color: #a3a3a0;
  padding: 0.3rem 0.2rem !important;
}

.OderItemHeader {
  background-color: rgb(242, 242, 242) !important;
  color: #000000 !important;
  text-align: left !important;
  text-decoration-color: #a3a3a0;
  padding: 0.3rem 0.2rem !important;
}

#back_button {
  float:right;
  cursor: pointer;
  color: #4183c4;
  font-weight: bold;
  text-decoration: underline;
}

#item_field {
  float:center;
  cursor: pointer;
  color: #4183c4;
  font-weight: bold;
  text-decoration: underline;
}

#no_border {
  border: none;
  width: 8rem;
}

#title_width {
  width: 20rem;
  border: none;
}

.order_details {
  margin-left: 0.5rem;
}

.order_details_tracking {
  margin-left: 0.5rem;
  text-decoration: underline;
}

.mouseHover {
  background-color : #4183c4;
  list-style-type: none; 
  border-radius : 5px;
  margin-top : 19px;
  position: absolute;
  z-index: 2;
}

.mouseHoverText {
  color: rgb(242, 242, 242);
  margin-left: -37px;
  margin-right: 1px;
  padding: 2px;
}

#field_width {
  border: none;
  width: 9rem;
}

#field_width_edit {
  border: none;
  width: 10rem;
}

#field_Desc_width {
  border: none;
  width: 18rem;
  white-space : pre!important;
}

.created_by {
  font-family: HelveticaNeue;
  font-size: 0.8rem;
}

.order_count {
  float: right;
  font-family: HelveticaNeueBold;
}

.excel_left {
  margin-left: 4rem;
}
#order-card-table {
  font-family: HelveticaNeue;
  font-weight: bold;
  width: 100%;
}
.order-card-field {
  font-family: HelveticaNeueBold;
  padding-left: 1rem;
}
.all-orders-card {
  background-color: #ffffff;
  padding: 1rem;
  margin-top: 0.6rem;
  border: 1px solid #000000;
}
.view-details-link {
  display: block;
  font-family: HelveticaNeue;
  font-weight: bold;
  color: #176db7;
  text-decoration: underline;
  cursor: pointer;
}
.tracking-data {
  font-size: 0.8rem;
}

.itemDetails {
  margin-left: 0rem;
  display: flex;
  flex-wrap: wrap;
}

.underline {
  text-decoration: underline;
}

.font-style {
  font-family: HelveticaNeueBold;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.orderImage {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}
#hideShowImages {
  cursor: pointer;
  color: #4183c4;
  font-weight: bold;
  text-decoration: underline;
  margin-right: 4rem;
}

#order_sts_dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

#search_order_status {
  width: 9.5rem;
  /* height: 0.8rem; */
  border:1px solid black;
  border-radius: 0rem;
  padding-left: 0.625rem;
  border-color: #a9a9a9 !important;
  padding-right: 2rem;
}

.sort_by {
  float: right;
}

#remove_border {
  border-left: none;
  text-align:center;
}

.padding th, td { 
  padding : 0.2rem !important;
}

.hide_created_by {
  display: none;
}
.OrderTotalitem{
  vertical-align: top;
}
.paginationAllorders{
  margin-left: 1rem;
}
.addressbookSize{
  vertical-align: top;
  margin-left: 2rem;
}
.search-allorder{
  margin-top: 1rem !important;
}
.order-pagination{
  display: flex;
  justify-content: space-between
}

.pagination-padding{
  padding-top : 1rem;
}
.filterorderstatus{
  margin-top: 2rem;
  flex: 0 0 20%;
}
.portrait-pagination {
  display: inline-table;
  text-align: center;
  margin: 0 auto;
  width: 80%;
}
#ds_orderStatusTable {
  flex-grow: 1;
}
.dsOrderDetailLg {
  justify-content: center;
}
.ds_orderDetails p {
  margin: 0 0.5rem;
}
.ds_print#printicon.icon {
  font-size: 1.5em;
}
.ds_allorderCount #back_button {
  float:none;
}
.dsOrderDetailLg {
  margin-top: 0 !important;
}
.dsOrderDetailLg .stickyTableOrderLevelDetails th {
  top: 14rem;
  background: #333333 !important;
  color: #fff !important;
  line-height: 1.1;
}
#ds_orderStatusTable .stickyTableEditOrder td {
  min-height: 2rem;
  border-bottom: 1px solid #000;
}
#ds_orderStatusTable .fa-eye {
  font-size: 1.2em;
  cursor: pointer;
}
.ds_os_msg {
  background-color: #ff0;
  color: #db2828;
  display: inline-block;
  padding: 0 0.25rem;
  margin-top: 0.5rem !important;
}
.all_orderTable {
  margin-top: 0 !important
}
.allOrderBtn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.75rem;
}
.allOrderBtn > div {
  min-width: 23rem;
}
.allOrderBtn > span {
  flex-grow: 1;
  text-align: center;
  color: #db2828;
  width: 70%;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .order-card-field {
    width: 50%;
  }
  .orderImage {
    width: 5rem;
    height: 5rem;
    margin-left: 15rem;
  }
  .itemDetails {
    margin-left: 0.5rem;
  }
  .allOrderstablemobile {
    margin-top: 4rem;
  }
  .sort_by {
    position: relative;
    right: 10rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .paginationAllorders{
    margin-left: 2rem;
  }
  .addressbookSize{
    margin-left: 0rem;
  }
  .excelHide {
    display: none;
  }
  #hideShowImages {
    display: none;
  }
  #field_width {
    width: 14rem;
  }
  .allOrderstablemobile {
    margin-top: 0rem;
    float: left;
    width: -webkit-fill-available;
  }
  .itemDetails {
    position: relative;
    top: 1rem;
  }
  .itemDetailsMobile {
    position: relative;
    left: 0;
    top: 0;
    min-width: 50%;
  }
  .itemDetailsMobileInvoice {
    position: relative;
    left: 0;
    top: 0;
    min-width: 50%;
  }
  .allorderCount {
    margin-top: 2rem;
  }
  .orderImage {
    width: 5rem;
    height: 5rem;
    margin-left: 7rem;
  }

  .allOrdersTableItem {
    margin-top: 2.5rem;
  }

  .sort_by {
    margin-top: 1rem;
  }

  .order_count {
    margin-top: 0.5rem;
  }
  .image-option {
    display: flex;
    justify-content: space-between;
  }
  .image-option #hideShowImages {
    display: block;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .displayFlex.allOrdersTable {
    display: block;
  }
  .allOrderstablemobile .portrait-pagination {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .mobile_sort > select {
    margin-bottom: 0.5rem;
  }

}

@media screen and (min-width:1025px) {
  .stickyTable th {
    position: -webkit-sticky;
    position: sticky;
    top: 5.7rem;
    background: #333333 !important;
    color: #fff !important;
    line-height: 1.1;
  }
  .stickyTableEditOrder th {
    position: -webkit-sticky;
    position: sticky;
    top: 13.5rem;
    background: #333333 !important;
    color: #fff !important;
    line-height: 1.1;
  }
  .stickyTableOrderLevelDetails th {
    position: -webkit-sticky;
    position: sticky;
    top: 18rem;
    padding-top:20px;    
    line-height: 1.1;
    }
}

.hidden-sim{
  visibility: hidden;
}
.includeAllOrderItems{
  margin-top: 0.5rem;
  margin-right: 1.5rem;
}
.includeAllOrderItems label{
  font-family: HelveticaNeueBold;
  margin-left: 5px;
  margin-top: 0;
}