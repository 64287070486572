@font-face {
  font-family: HelveticaNeue;
  src: url('../../fonts/HelveticaNeue Light.woff2');
  src: url("../../fonts/HelveticaNeue Light.eot"),
  url("../../fonts/HelveticaNeue Light.woff") format("woff");
}

@font-face {
  font-family: HelveticaNeueMedium;
  src: url('../../fonts/HelveticaNeue Medium.woff2'),
  url("../../fonts/Helvetica Neu Medium.woff") format("woff");
}

@font-face {
  font-family: HelveticaNeueBold;
  src: url('../../fonts/Helvetica Neu Bold.woff2');
  src: url("../../fonts/Helvetica Neue Bold.eot"),
  url("../../fonts/Helvetica Neu Bold.woff") format("woff");
}
.caps {
  text-transform: uppercase;
}
.breadcrumb a {
  cursor: pointer;
  font-family: HelveticaNeue;
  font-size: 0.7rem !important;
  text-decoration: underline;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.breadcrumb span{
  font-size: 0.7rem !important;
}
.header-display{
  display: flex;
  align-items:center;
  flex-wrap: wrap;
}
.options-div {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap:wrap;
  justify-content: flex-end;
  margin-bottom: 1rem;
  align-items: center;
}
.select-range {
  height: 1.5rem;
}
#vendor_name_padding {
  padding: 0rem !important;
}

.product-name {
  font-size: 1.28rem;
  font-weight: bolder;
}
.product-title {
  font-size: 1.28rem;
  font-weight: bolder;
}
.vendor-span,
.remove-all-span {
  color: #2b77f2;
  text-decoration: underline;
  padding-left: 1rem;
}
.vendor-span {
  font-size: 0.85rem;
}
.remove-all-span {
  cursor: pointer;
}
.fields-div {
  margin-right: 0.5rem;
  display: flex;
  font-size: 0.4rem;
  padding: 0.5rem !important;
  background-color: #ffffff !important;
}
.fields-div-one {
  width: 13rem !important;
  margin-right: 0.5rem;
  display: flex;
  font-size: 0.8rem;
  padding: 0.5rem !important;
  background-color: #ffffff !important;
}
.compareImage {
  width: 9.75rem;
  height: 10.71rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.compare-root {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: HelveticaNeueBold;
  line-height: 2rem;
}
.ui.grid.compare-container{
  margin-top:initial;
  margin-left:initial;
  margin-right:initial;
  margin-bottom:initial;
  width:100vw;
}
.row1 {
  height: 16rem;
  border-bottom: 0.3rem solid #000000;
  text-align: center;
}
.row1 > img {
  margin: auto;
}
.row1 > p {
  margin-bottom: 0;
}
.compare-remove-link,
.compare-hide-link {
  color: #2b77f2;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.compare-remove-link {
  font-size: 0.8rem;
}
.compare-container .heart {
  font-size: 0.9rem;
}
.compare-container .product-field.long-desc {
  overflow: hidden;
}
.grid-mobilecmp .heart {
  font-size: 0.9rem;
}
.comparison-field {
  margin-right: 1rem;
  display: flex;
  font-weight: bold;
}
.ui.column.grid>[class*="four wide"].column.mobComp{
  width: 30% !important;
  margin: 0;
}
.ui.column.grid>[class*="five wide"].column.mobComp{
  width: 32% !important;
  margin: 0;
}
.ui.column.grid>[class*="five wide"].column.mobComp .compareImage{
  width: 5.75rem;
}
.ui.column.grid>[class*="five wide"].column.mobComp .compareImage img{
  width:100%;
}
.product-field {
  display: flex;
  font-weight: bold;
}

.row11 {
  min-height: 1rem;
  max-height: 4rem;
  height: 4rem;
}

.row5 {
  min-height: 2rem;
  max-height: 2rem;
}

.row2,
.row3,
.row4,
.row6,
.row7,
.row8,
.row9,
.row10,
.row13 {
  min-height: 1rem;
  max-height: 2rem;
}
.row9, .row10 {
  height: 2rem;
}
.row18 {
  height: 3rem;
}
.rounded-add-to-cart {
  border-radius: 0.4rem;
  padding: 0rem !important;
}
.ui.table tr td {
  border-top: 0px !important;
}
.ui.table td {
  padding: 0 !important;
  margin: 0 !important;
}
#product_name_details {
  padding-top: 1rem;
  font-family: HelveticaNeueBold;
  padding-bottom: 0rem;
}
#product_name_details_review,
#store_questions {
  float: right;
  padding-bottom: 0rem;
  padding-left: 0rem;
  font-size: 0.875rem;
  text-decoration: underline;
  font-weight: bold;
  font-family: HelveticaNeueBold;
  color: #2b77f2;
}
.backLink{
  padding-bottom: 0rem;
}
#product_name_details_review {
  color:#2b77f2;
  cursor: pointer;
}
.backLink{
  padding-bottom: 0rem;
}
.back_button {
  float:right;
  color:#2b77f2;
  text-decoration: underline;
  justify-content: flex-end;
  font-size: 0.875rem;
  font-family: HelveticaNeueBold;
  cursor: pointer;
}
.back_button:hover {
  color: blue;
  text-decoration: none; 
}
.link_button {
  color:#4183c4;
  text-decoration: underline;
  justify-content: flex-end;
  font-size: 0.875rem;
  font-family: HelveticaNeueBold;
  cursor: pointer;
  margin: 0.5rem
}
.link_button:hover {
  color: blue;
  text-decoration: none; 
}
#item_num {
  font-family: HelveticaNeueBold;
  font-size: 0.825rem;
}
#model {
  padding-left: 0.3rem;
  font-family: HelveticaNeueBold;
  font-size: 0.825rem;
}
#pack {
  padding-left: 0.3rem;
  font-family: HelveticaNeueBold;
  font-size: 0.825rem;
}
.green_text_details {
  padding-left: 0.3rem;
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  color: #008000;
}
.red_text_details {
  padding-left: 0.3rem;
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  color: #cb1e00;
}
#on_ship_later {
  padding-left: 0.3rem;
  font-family: HelveticaNeueBold;
  font-size: 0.825rem;
}
.product_details {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  font-family: HelveticaNeue;
  font-size: 1rem;
  letter-spacing: 0.05rem;
}

.pdp_top_details {
  padding-top: 0.6rem !important;
  font-size: 1rem;
  font-family: HelveticaNeueBold;
  line-height: 1.15rem;
}

.address {
  font-family: HelveticaNeueBold;
  font-size: 0.9rem;
}

.check_all_warehouse {
  text-decoration-line: underline;
  font-family: HelveticaNeueBold;
  font-size: 0.9rem;
}

.check_all_warehouse a {
  text-decoration-line: underline;
}

.Alias_sku {
  padding-top: 0.2rem;
  font-family: HelveticaNeueBold;
  font-size: 0.9rem;
}

.description {
  font-family: HelveticaNeue;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  position: relative;
}


#retail_details {
  table-layout: auto;
  font-family: HelveticaNeueBold;
  font-size: 0.9rem;
  width: 100%;
  letter-spacing: 0.05rem;
}

#retail_details td {
  border-bottom: #ffffff 1px solid !important;
}

.assortment-btn {
  font-family: HelveticaNeueBold;
  background-color: #ffffff;
  border-color: #4286f4;
  color: #4286f4;
  border-radius: 0.1rem;
  border-width: 0.1rem;
}

.red_text_details {
  color: #cb1e00;
  text-align: center;
  font-weight: bold;
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  padding-top: 0rem;
  margin-top:1rem;
}

.Alert {
  background-color: #f9e8a2;
  color: #cb1e00;
  text-align: center;
  font-weight: bold;
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  float: center;
}
.down_table.table tr td {
  border: none;
  font-family: HelveticaNeue;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
}
.down_table {
  background-color: #ffffff;
  border-radius: 0rem !important;
}
.order_header {
  background: #f7f4ea;
  color: #000000;
  font-family: HelveticaNeueBold;
  font-size: 0.8rem;
  text-align: center;
}
.invoice-header {
  display: flex;
  justify-content: space-around;
  padding-left: 0.1rem;
  border-bottom: 1px solid #000000;
}
.invoice-card {
  padding-left: 0.1rem;
  border: 1px solid #000000;
  margin: 1rem;
}
.invoice-body {
  font-weight: 400;
}
#order_table {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: none;
}

.pdp-planograms {
  padding-left: 0.5rem;
  padding-top: 0rem;
  font-family: HelveticaNeueBold !important;
  font-size: 0.65rem;
  text-decoration-line: underline;
}
.warning {
  padding-left: 1.3rem;
  padding-top: 0rem;
  font-family: HelveticaNeueBold;
  font-size: 0.825rem;
  padding-left: 0.4rem;
}
.warning_icon {
  /* padding-top: 0.2rem; */
  width: 1rem;
  height: 1rem;
}
.warning_container {
  padding-top: 1rem;
}
.warning_prop65 {
  padding-top: 0rem;
}
#button_grid {
  font-family: HelveticaNeue;
  border-radius: 0rem;
}

.add-to-cart-btn {
  font-family: HelveticaNeue !important;
  padding: 0.3rem 2.5rem !important;
}

.selected-products-div {
  position: fixed;
  background-color: #ffffff;
  border: #808080 0.02rem solid;
  bottom: 0;
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  z-index: 10;
}

.selected-div-right-options {
  position: fixed;
  right: 0.1rem;
  height: 100%;
  margin-top: 5%;
  background-color: #ffffff;
}

#grid_segment,
.grid_segment_compare_list {
  margin: 0rem 0rem 0rem 1rem;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
}

.grid_segment_compare_list {
  height: 14rem;
  text-align: center;
  padding-right: 2rem;
  padding-left: 2rem;
}

.image_compare_panel {
  height: 70%;
  width: auto;
}

.cell_tab {
  background: #f2f2f2;
}

#printicon {
  float: right;
  height: 1.5rem !important;
}

#Detailed_specification {
  font-family: HelveticaNeue;
  padding-bottom: 0rem;
  letter-spacing: 0.05rem;
}

.compare-n-fav{
  display:flex;
  float: right;
  font-size: 0.8rem;
}
.fav_icon_hover{
  position: absolute;
  top: 0rem;
  right: 8rem;
  font-size: 0.85rem;
  font-weight: 700;
}

.fav_icon_hover_grid{
  font-size: 0.8rem;
  top: 1.9rem;
  float: right;
  margin-right: 0.2rem;
}

.Discontinued_and_find_like_items{
  text-align: center;
  padding-bottom: 1rem;
}

.find_like_items{
  margin-left: 1rem;
  font-family: HelveticaNeueBold;
  text-decoration: underline;
  font-size: 0.875rem;
}

.season_dates{
  text-align: center;
  font-weight: bold;
  color: #c8102e;
}

.green_thumb{
  font-weight: bold ;
  color: #00FF00;
}

.prop65_color{
  color: #2b77f2;
}
.prop65_checkout{
  color: #b21e1e
}
.align_twoColumn > span {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
.align_twoColumn > b {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
.hide_display {
  display: none !important;
}
.alternativeItem {
  display: inline-block;
  margin-left: 1rem;
}
.alternativeItemImg {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}
.alternativeItemImg img {
  max-width: 100%;
  max-height: 100%;
}
.altItemNo {
  margin: 0 0.5rem;
  font-family: HelveticaNeueBold;
  font-size: 0.9rem;
}
@media only screen and (max-width: 767px) {
  .selected-products-div {
    overflow: hidden;
    z-index: 1;
  }
  .selected-div-right-options{
    height:fit-content;
    margin-top: 2rem;
  }
  .grid_segment_compare_list{
    padding-left: 0rem;
    height: 16rem;
    margin:0rem;
  }
  .vendor-span {
    display: block;
    padding-left: 0rem;
  }

  .header_user_icons {
    position: relative;
    right: 0rem;
  }

  #grid_segment{
    margin: 0rem !important;
  }
  .option-items {
    flex-flow: wrap;
  }

  .sortBy {
    margin-top: 1rem;
  }

  .sortMyWarehouse {
    border: 0.1rem;
    border-radius: 0rem;
    background-color: #d3d3d3;
  }
  .Alert {
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sortMyWarehouse {
    border: 0.1rem;
    border-radius: 0rem;
    background-color: #d3d3d3;
  }
  .option-items {
    margin-right: -2rem;
  }
  .compareImage {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) {
  /* Scemantic UI is using  importatnt so below overwrite needs important. 
  Cant use id for table as same table might be used in the same page,
  which will result in same id for two table in same page*/
  .ui.table.down_table tr:after {
    content: ' ';
    display: block;
    clear: both;
  }
  .compareImage {
    margin-top:1rem;
  }
  .ui.table.down_table tr {
    width: 100% !important;
  }
  .ui.table.down_table:not(.unstackable) tr > td:empty {
    display: none !important;
  }
  .ui.table.down_table:not(.unstackable) tr > td:nth-child(2n + 1) {
    clear: left;
  }

  .ui.table.down_table:not(.unstackable) tr > td {
    width: 50% !important;
    float: left;
    min-height: 30px;
  }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .options-div{
    justify-content: flex-start;
  }
}
.substituteContainer{
  display: flex;
  flex-direction: column;
  font-weight: bolder;
  color:rgb(203, 30, 0);  
  font-family: HelveticaNeueBold,sans-serif;
}
.substituteTitle{
  font-size: 1.125rem;
}
.substituteContainer ul li{
  list-style: none;
  font-size: 1rem;
  }