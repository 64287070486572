.imagelayout {
    width: 100%;
    height: 12.5rem;
    padding-bottom: 2rem;
    padding-top: 0.5rem;
}
.shopping-landing-page-container {
    padding: 0.5rem 1rem;
}

.daysalign {
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
}
.timer-setting {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.dealImg{
    display: flex;
    align-items: center;
    height:21.15rem;
}
.deal-navigator-img{
    max-width:80%;
    margin: 0 auto;
   
}
.shoppingNameText {
  font-family: HelveticaNeueBold;
  font-size: 1.5rem;
  padding-top: 5rem;
  padding-left: 1rem;
  vertical-align: middle;
  text-align: center;
}

.noShoppingAreas {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 37rem;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    margin-left: 30%;
}

.timer {
  font-weight: 300;
  color: #dc143c;
  font-family: HelveticaNeueBold;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  padding-bottom: 0.6rem;
}

.page_details_shoppingArea{
    margin-top: 0.2rem;
    margin-left: 0rem;
    font-family: HelveticaNeueBold;
    width:100%;
}

.orderWows {
    height: 0rem;
}

.shopping_area_content--item {
    flex: 0 0 32.7%;
    height: auto;
    max-height: 21rem;
    min-height: 21rem;
    background-color: #ffffff;
    margin: 0.2rem 3px;
    display: flex;
    flex-wrap: wrap;
    min-width: 21rem;
    border: 1px solid #808080
}

.ctbTile {
    position: relative;
}
.ctbTile > .tileDiv {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    height: 100%;
    position: relative;
    width: 100%;
    padding-top: 2rem;
}
.ctbTile img {
    max-width: 15rem;
    height: auto;
}
.ctbTile .deal-navigator-footer {
    position: absolute;
    bottom: 0;
    transform: none;
    width: 100%;
}

.shopping_page_content--item .item_footer {
    flex: 0 0 100%;
    padding: 0;
    text-align: center;
    background-color: #168c87;
    display: none;
    align-self: flex-end;
    color: #fff;
    font-size: 1.2rem;
    padding: 5px;
    font-weight: 400;
    cursor: pointer;
  }

.item_footer {
    flex: 0 0 100%;
    text-align: center;
    background-color: #168c87;
    align-self: flex-end;
    color: #fff;
    font-size: 1.2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    cursor: pointer;
    font-family: Helvetica;
}
.deal-navigator-footer{
    position: relative;
    transform:translateY(-2.5rem);
}
.daysalign {
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
}
.timer-setting {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.item_footer_upcoming {
    flex: 0 0 100%;
    text-align: center;
    background-color: #808080;
    display: none;
    align-self: flex-end;
    color: #fff;
    font-size: 1.2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    cursor: pointer;
    font-family: Helvetica;
}

.shopping-landing-page-content-container {
    padding-top: 0rem;
    padding-left: 6rem;
    padding-bottom: 1rem;
    padding-right: 6rem;
}
  
.landing_page_content--container {
    display: flex;
    flex-wrap: wrap;
}
.instructionLinksWow{
    font-family: HelveticaNeueBold;
    font-size: 1.3rem;
    padding-top: 5rem;
    padding-left: 7rem;
    display: flex;
    align-items: center;
    height: 0rem;
}

.instructionLinksNW{
    font-family: HelveticaNeueBold;
    font-size: 1.3rem;
    padding-top: 1rem;
}

.generalOrdering {
    text-align: center;  
    align-content: center;
    margin-left: 1.2rem;
}

.orderWows{
    height: 0rem;
}

@media only screen and (max-width: 767px) {
    .shopping_area_content--item{
        flex: 100%;
        border: unset;
    }
    .shopping-landing-page-container {
        padding: unset
    }
    .shopping-landing-page-content-container {
        padding: unset;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .landing_page_content--container {
        padding: unset;
    }
    .shopping-landing-page-content-container {
        padding: unset;
    }
    .shopping_area_content--item {
        flex: 0 0 49%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .instructionLinksNW{
        padding-left: 3rem;
    }
}
